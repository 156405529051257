<template>
  <v-sheet class="item" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="8" class="py-3">
            <!-- <h1 class="custom-header-blue-text m-0">Items</h1> -->
          </v-col>
          <v-col md="4" class="text-right py-3">
            <v-btn
              color="blue darken-4"
              depressed
              tile
              class="mr-4 white--text"
              v-on:click="importDialog = true"
            >
              Import
            </v-btn>
            <v-btn
              color="blue darken-4"
              depressed
              tile
              class="white--text"
              :to="{ name: 'item-create', query: { t: new Date().getTime() } }"
            >
              New Item
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <SearchCriteria
      v-if="listingSearch"
      :search="listingSearch"
      search-string="Item #, Name, Category"
      v-on:close-search="resetSearch"
    ></SearchCriteria>
    <v-simple-table fixed-header class="bt-table item-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th :key="item_h + '_' + index" v-for="(item_h, index) in items_table_heading">
              {{ item_h }}
            </th>
            <!-- <th width="50">Actions</th>
            <th width="50">Item #</th>
            <th>Name</th>
            <th width="150">Category</th>
            <th width="100">Price</th>
            <th width="100">Active</th>
            <th width="200">Created At</th>
            <th width="200">Last Modified At</th> -->
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="items.length">
            <tr
              :class="bkey % 2 === 0 && 'blue lighten-5'"
              v-for="(row, bkey) in items"
              :key="bkey"
              class="item-listing-tr"
            >
              <td width="40">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      fab
                      dark
                      x-small
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      :to="{
                        name: 'item-update',
                        params: { id: row.id },
                        query: { t: new Date().getTime() },
                      }"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      fab
                      dark
                      x-small
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      v-on:click.stop.prevent="deleteConfirm(row)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
              <td width="50"><Chip small :text="row.barcode"></Chip></td>
              <td
                width="800"
                style="max-width: 800px"
                class="text-wrap"
                link
                v-on:click="routeToDetail(row.id)"
              >
                <v-layout>
                  <v-flex md2>
                    <ImageTemplate
                      circle
                      :src="row.image"
                      custom-class="border-light-grey max-width-80px"
                    ></ImageTemplate>
                  </v-flex>
                  <v-flex class="ml-4 my-auto">
                    <div>
                      <p class="text-truncate m-0">
                        <ValueTemplate
                          class="text-wrap"
                          v-model="row.name_en"
                          title="Name (In English)"
                        ></ValueTemplate>
                      </p>
                      <p class="text-truncate m-0">
                        <ValueTemplate
                          v-model="row.name_ch"
                          title="Name (In Chinese)"
                        ></ValueTemplate>
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </td>
              <td link v-on:click="routeToDetail(row.id)" width="150">
                <ValueTemplate v-model="row.category" title="Category"></ValueTemplate>
              </td>
              <td link v-on:click="routeToDetail(row.id)" width="100">
                <ValueTemplate v-model="row.amount" currency title="Price"></ValueTemplate>
              </td>
              <td width="100" align="center" valign="middle">
                <v-switch
                  v-model="row.status"
                  inset
                  :loading="sLoading"
                  :disabled="!!sLoading"
                  color="green darken-3"
                  v-on:change="updateStatus(row.id, row.status)"
                  true-value="active"
                  false-value="in-active"
                ></v-switch>
              </td>
              <td link v-on:click="routeToDetail(row.id)" width="200">
                <ValueTemplate v-model="row.added_at" datetime title="Created At"></ValueTemplate>
              </td>
              <td link v-on:click="routeToDetail(row.id)" width="200">
                <ValueTemplate
                  v-model="row.updated_at"
                  datetime
                  title="Last Modified At"
                ></ValueTemplate>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no item at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 7" :key="idr">
            <td v-for="idk in 7" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="items.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="importDialog" card-text-class="px-4 py-0">
      <template v-slot:title> Import Items </template>
      <template v-slot:body>
        <v-container>
          <v-btn
            color="blue darken-4"
            depressed
            tile
            class="mr-4 white--text"
            v-on:click="downloadSample()"
            >Download Sample</v-btn
          >
          <h4 class="mt-4">
            Note: The first line in downloaded xlsx file should remain as it is. Please do not edit
            or change the order of columns.
          </h4>
          <v-layout class="my-4">
            <v-flex md2>
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-flex>
            <v-flex md10>
              <v-file-input
                v-model="excelFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Excel File"
                outlined
                dense
              ></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="uploadExcel()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="importDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <DeleteTemplate
      type="template"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getTemplate()"
    ></DeleteTemplate>
  </v-sheet>
</template>
<script>
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import SearchCriteria from "@/view/components/SearchCriteria";
import ValueTemplate from "@/view/components/ValueTemplate";
import ImageTemplate from "@/view/components/Image";
import { GET_ITEMS, UPDATE_STATUS, IMPORT_ITEMS } from "@/core/lib/item.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";

export default {
  name: "item-listing",
  title: "Listing Item",
  data() {
    return {
      speedDial: [],
      items: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      importDialog: false,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      excelLoading: false,
      sLoading: null,
      excelFile: null,
      items_table_heading: [
        "Actions",
        "Item #",
        "Name",
        "Category",
        "Price",
        "Active",
        "Created At",
        "Last Modified At",
      ],
    };
  },
  watch: {
    currentPage() {
      this.getItems();
    },
    listingSearch() {
      this.getItems();
    },
  },
  methods: {
    async uploadExcel() {
      try {
        const formData = new FormData();
        formData.append("excel", this.excelFile);
        this.excelLoading = true;
        await IMPORT_ITEMS(formData);
        this.importDialog = false;
        this.excelFile = null;
        this.getItems();
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.excelLoading = false;
      }
    },
    downloadSample() {
      const url = this.$assetURL("sample-imports/Items.xlsx");
      window.open(url, "_blank");
    },
    async updateStatus(id, status) {
      try {
        this.sLoading = "warning";
        await UPDATE_STATUS(id, { status });
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.sLoading = undefined;
      }
    },
    getStatusColor(status) {
      if (status == "active") {
        return "green text--white";
      }
      if (status == "in-active") {
        return "red lighten-1 text--white";
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "item-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "item-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `item/${id}`;
      this.deleteDialog = true;
    },
    async getItems() {
      try {
        const form = {
          current_page: this.currentPage,
          search: this.listingSearch,
        };
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ITEMS(form);
        this.items = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
  },
  mounted() {
    this.getItems();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Items");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    Chip,
    Dialog,
    ValueTemplate,
    DeleteTemplate,
    ImageTemplate,
    SearchCriteria,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.item-table .v-data-table__wrapper {
  height: calc(100vh - 200px) !important;
}
.item-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 99;
}

.item-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
